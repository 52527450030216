import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IPaginatedService,
  IPaginatedServiceParamters,
} from 'src/app/core/models/ITable';
import {
  AccountMovement,
  MovementTypeEnum,
  MovementTypeUrl,
} from 'src/app/core/models/AccountMovement';
import { TranslateService } from '../../services/translate.service';
import { DateService } from '../../services/date.service';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-balance-account-grid',
  templateUrl: './balance-account-grid.component.html',
  styleUrls: ['./balance-account-grid.component.css'],
})
export class BalanceAccountGridComponent implements OnInit {
  @Input() tableService: IPaginatedService;
  @Input() id: number;
  @Input() itemsPerPage: number = 10;
  @Input() contractMode: boolean = false;
  @Input() contractCurrency: number = null;

  iconQuestion = faQuestionCircle;

  accountMovements: AccountMovement[];
  page: number = 1;

  totalItems: number = 10;
  periodVal: number = 1;
  currencyVal: number = 1;
  query: string = 'CreatedOrNot=true';
  emptyTable: boolean = true;

  constructor(
    public translate: TranslateService,
    private router: Router,
    public dateService: DateService,
  ) {}

  ngOnInit() {
    this.currencyVal = this.contractCurrency ? this.contractCurrency : 1;
    this.getElements(this.page, this.itemsPerPage);
  }

  getElements(page, itemsPerPage) {
    var paginated: IPaginatedServiceParamters = {
      id: this.id,
      query: this.query,
      pageNumber: page,
      pageSize: itemsPerPage,
      period: this.periodVal,
      currency: this.currencyVal,
    };

    this.tableService.getAllPaginated(paginated).subscribe(
      (response: any) => {
        this.accountMovements = response.result;
        this.emptyTable = false;
        this.totalItems = response.pagination.totalItems;
      },
      (err) => {
        this.emptyTable = true;
        this.accountMovements = [];
      },
    );
  }

  pageChanged(event) {
    this.page = event;
    this.getElements(event, this.itemsPerPage);
  }

  setPeriod(val) {
    this.periodVal = val;
    this.getElements(this.page, this.itemsPerPage);
  }

  setCurrency(val) {
    this.currencyVal = val;
    this.getElements(this.page, this.itemsPerPage);
  }

  movementTypes(value): string {
    return MovementTypeEnum[value];
  }

  movementTypesUrl(value): string {
    return MovementTypeUrl[value];
  }

  redirect(id, type) {
    if (type != 4 || 6) {
      this.router.navigateByUrl(this.movementTypesUrl(type) + id);
    }
  }
}
