import { MovementType } from './CashRegister';
export interface AccountMovement {
  id: number;
  amount: number;
  description: string;
  movementType: MovementTypeEnum;
  referenceId: number;
  partialAccount: number;
  date: Date;
  realEstateAgencyId: number;
  accountBalanceId: number;
}

export enum MovementTypeEnum {
  Payment = 1,
  TransferPayment = 2,
  Return = 3,
  PendingReturn = 4,
  Fee = 5,
  PendingFee = 6,
  Retention = 7,
  PaymentDeletion = 8,
  ReturnDeletion = 9,
  FeeDeletion = 10,
  RetentionDeletion = 11,
}

export enum MovementTypeUrl {
  Payment = '/payments/',
  TransferPayment = 'transfers/',
  Return = '/historical-returns/',
  PendingReturn = '',
  Fee = '/fees/historical',
  PendingFee = '',
}

/* export const MOVEMENT_TYPE_URL: MovementTypeUrl = {
    Payment: {url: '/payments/', text: 'text'},
    TransferPayment: {url: 'transfers/', text: 'text'},
    Return: {url: '/returns/', text: 'text'},
    PendingReturn: {url: '', text: 'text'},
    Fee: {url: '/real-estate/fees/', text: 'text'},
    PendingFee: {url: '', text: 'text'}

}

export interface MovementTypeUrl {
    Payment: {url: string, text: string};
    TransferPayment: {url: string, text: string};
    Return: {url: string, text: string},
    PendingReturn: {url: string, text: string},
    Fee: {url: string, text: string},
    PendingFee: {url: string, text: string}
} */
