import { Component, Input, OnInit } from '@angular/core';
import { PhoneNumber } from '../../core/models/PhoneNumber';
import { Router } from '@angular/router';
import {
  faHome,
  faEnvelope,
  faPhone,
  faIdCard,
  faBirthdayCake,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { User } from '@auth0/auth0-spa-js';
import { Property } from 'src/app/core/models/Property';

@Component({
  selector: 'app-person-viewer',
  templateUrl: './person-viewer.component.html',
  styleUrls: ['./person-viewer.component.css'],
})
export class PersonViewerComponent implements OnInit {
  fullName: string = '';
  age: number = 0;
  email: string = '';
  address: string = '';
  phones: PhoneNumber[] = [];
  id: number = 0;
  personUrl: string = '';

  @Input() personType: string;
  @Input() personToView: any;
  @Input() userData: User;
  @Input() properties: Property[];
  @Input() type: string;

  iconHome = faHome;
  iconEmail = faEnvelope;
  iconPhones = faPhone;
  iconDoc = faIdCard;
  iconBday = faBirthdayCake;
  iconAddress = faBuilding;

  avatarInitial: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.avatarInitial = this.userData?.person.fullName;
    if (this.personType == 'guarantors') {
      if (this.personToView.person.businessName) {
        this.fullName = this.personToView.person.businessName;
      } else {
        this.fullName = `${this.personToView.person.firstName} ${this.personToView.person.lastName}`;
      }
      this.dataPopulation(this.personToView);
    } else {
      console.log(this.personToView);

      this.fullName = this.personToView.user?.person.fullName;
      this.dataPopulation(this.personToView.user);
    }
    this.id = this.personToView.id;
    this.personUrl = `/${this.personType}/${this.id}`;
  }

  propertyUrl(id) {
    this.router.navigateByUrl(`/properties/${id}`);
  }

  dataPopulation(person) {
    this.age = person?.person.age;
    this.address = this.getAddress(person.person.address);
    this.email = person?.email || 'No hay direccion de email';
    this.phones = person?.person.phones;
  }

  getAddress(addressProperty) {
    let street = addressProperty.streetAddress ?? '';
    let city = addressProperty.city ?? '';
    let state = addressProperty.state ?? '';
    let addresses: string = street + ' ' + city + '; ' + state;

    if (addresses.trim() != ';') {
      return street;
    }
    return 'No hay direccion';
  }
}
