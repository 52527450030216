import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DateService } from 'src/app/services/date.service';
import { IconService } from 'src/app/services/icon.service';
import { LiabilitiesService } from 'src/app/services/liabilities.service';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstallmentWithRequirement } from 'src/app/core/models/InstallmentWithRequirement';

@Component({
  selector: 'app-requirement-list-accumulator',
  templateUrl: './requirement-list-accumulator.component.html',
  styleUrls: ['./requirement-list-accumulator.component.css'],
})
export class RequirementListAccumulatorComponent implements OnInit {
  @ViewChild('servicePayForm') modal: TemplateRef<any>;

  @Input() contractId?: number;
  @Input() _ReloadAccumulatorCards?: Observable<number>;
  @Input() interarctive?: boolean = true;

  @Output() updateInstReqs = new EventEmitter();

  reqAccKeys: string[] = [];
  reqAccumulator = {};
  noReq: boolean = true;

  iconQuestion = faQuestionCircle;
  selectedRequirement: string = '';

  constructor(
    private liabilityService: LiabilitiesService,
    public iconService: IconService,
    public dateService: DateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.reloadAccCards();
    if (this._ReloadAccumulatorCards) {
      this._ReloadAccumulatorCards.subscribe(() => {
        this.reloadAccCards();
      });
    }
  }

  refresh(value) {
    this.updateInstReqs.emit(value);
    this.reloadAccCards();
    this.modalService.dismissAll();
  }

  reloadAccCards() {
    this.reqAccKeys = [];
    let contractID = this.contractId;
    if (contractID != null) {
      this.liabilityService
        .getInstallmentRequirementContract(contractID)
        .subscribe((result: InstallmentWithRequirement[]) => {
          this.liabilityService.insWithReq = result;
          console.log(result);
          console.log(result[0]);
          result[0].installmentRequirementStates.forEach((state) => {
            this.reqAccumulator[state.requirementName] = [];
          });
          result.forEach((element) => {
            let elementDate = new Date(element.installment.fromDate);
            let todayInstallment = new Date();
            if (elementDate.getTime() <= todayInstallment.getTime()) {
              element.installmentRequirementStates.forEach((elementState) => {
                elementState.installmentRequirement;
                if (
                  !elementState.installmentRequirement &&
                  elementState.installmentRequirement?.installmentStatusId != 1
                ) {
                  this.reqAccumulator[elementState.requirementName].push({
                    instReqState: elementState,
                    installment: element.installment.id,
                  });
                  if (
                    this.reqAccumulator[elementState.requirementName][
                      'installments'
                    ]
                  ) {
                    this.reqAccumulator[elementState.requirementName][
                      'installments'
                    ].push(element.installment);
                  } else {
                    this.reqAccumulator[elementState.requirementName][
                      'installments'
                    ] = [];
                    this.reqAccumulator[elementState.requirementName][
                      'installments'
                    ].push(element.installment);
                  }
                }
              });
            }
          });

          let tempKeys = Object.keys(this.reqAccumulator);
          tempKeys.forEach((key) => {
            this.reqAccumulator[key].length <= 0
              ? delete this.reqAccumulator[key]
              : false;
          });

          this.reqAccKeys = Object.keys(this.reqAccumulator);

          if (this.reqAccKeys.length == 0) {
            this.noReq = true;
          } else {
            this.noReq = false;
          }
        });
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  open(reqName) {
    if (this.interarctive) {
      this.selectedRequirement = reqName;
      this.modalService.open(this.modal, { size: 'lg' });
    }
  }
}
