import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faEye, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  GenerationTypeEnum,
  Requirement,
} from 'src/app/core/models/Requirement';
import { IconService } from 'src/app/services/icon.service';
import { RequirementService } from 'src/app/services/requirement.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ToastService } from 'src/app/services/toast.service';
import { RequirementForAdd } from 'src/app/core/models/RequirementForAdd';

@Component({
  selector: 'app-requirement-form',
  templateUrl: './requirement-form.component.html',
  styleUrls: ['./requirement-form.component.scss'],
})
export class RequirementFormComponent implements OnInit {
  iconPlus = faPlus;
  iconSee = faEye;
  iconTrash = faTrash;
  deleteTooltip = '';
  firstDeadline: NgbDateStruct;
  display: boolean = true;
  addUnitToolTip: string;
  finalextraRequirementIsEmpty = false;
  extraRequirementList: string[] = [];
  generationTypeValue: number;
  newIcon: IconDefinition;
  submitted: boolean = false;


  @Input() edit?: boolean = false;
  @Input() editData?: any;
  @Input() createInMemory?: any;
  @Input() createInMemoryArray?: any;

  @Output() closeAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();


  requirementFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    extraRequirements: new UntypedFormControl(''),
    generationType: new UntypedFormControl('', Validators.required),
    icon: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public requirementService: RequirementService,
    public iconService: IconService,
    private toastService: ToastService,
  ) {}
  generationTypes: any[] = [];

  ngOnInit() {
    for (let item in GenerationTypeEnum) {
      if (isNaN(Number(item))) {
        this.generationTypes.push({
          text: item,
          value: GenerationTypeEnum[item],
        });
      }
    }

    if (this.edit) {
      this.generationTypeValue = this.editData.generationTypeId;

      this.requirementFormGroup.patchValue({
        name: this.editData.name,
        extraRequirements: null,
        generationType: this.editData.generationTypeId,
      });
      this.selectIcon(this.editData.icon);
      this.extraRequirementList = this.editData.extraRequirements;
    }
  }

  ngAfterViewInit() {
    //
  }

  submitRequirement() {
    if (this.requirementFormGroup.invalid) {
    } else {
      if (this.edit) {
        this.editRequirement();
      } else {
        this.submitted = true;
        this.addRequirement();
      }
    }
  }

  addRequirement() {
    let requirementForSubmit: RequirementForAdd;

    requirementForSubmit = {
      name: this.requirementFormGroup.value.name
        ? this.requirementFormGroup.value.name
        : null,
      extraRequirements: this.extraRequirementList,
      generationTypeId: this.generationTypeValue,
      icon: this.requirementFormGroup.value.icon,
    };

    this.requirementService
      .createRequirement(requirementForSubmit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Requerimiento creado');
        this.closeAction.emit();
      });
  }

  editRequirement() {
    let requirementForEdit: Requirement;

    requirementForEdit = {
      id: this.editData.id,
      name: this.requirementFormGroup.value.name
        ? this.requirementFormGroup.value.name
        : null,
      extraRequirements: this.extraRequirementList,
      generationTypeId: this.generationTypeValue,
      icon: this.requirementFormGroup.value.icon,
    };

    this.requirementService
      .updateRequirement(this.editData.id, requirementForEdit)
      .subscribe((response) => {
        this.toastService.showToast('success', 'Requerimiento actualizado');
        this.closeAction.emit();
      });
  }

  addNewExtraRequirement(extraRequirement: string) {
    if (
      extraRequirement &&
      !this.extraRequirementList.some((x) => x === extraRequirement)
    ) {
      this.extraRequirementList.push(extraRequirement);
      this.finalextraRequirementIsEmpty = false;
    }
  }

  deleteFromExtraRequirementList(index) {
    this.extraRequirementList.splice(index, 1);
  }

  selectIcon(iconSelected) {
    this.requirementFormGroup.patchValue({
      icon: iconSelected,
    });

    this.newIcon = this.iconService.getIcon(iconSelected);
  }

  validate(field: string) {
    return (
      this.requirementFormGroup.controls[field].errors &&
      this.requirementFormGroup.controls[field].touched
    );
  }

  
  cancel(){
    this.cancelAction.emit();
  }

}
